import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { REGISTRATION_PAGE, COMING_SOON_PAGE} from "./settings/constant";

const ComingSoonPage = React.lazy(() => import("containers/ComingSoon"));
const SignUpPage = React.lazy(() => import("containers/Auth/SignUp/SignUp"));

export default function AppRoutes() {
  return (
    <Routes>
      {/* Coming Soon page as root */}
      <Route
        path={COMING_SOON_PAGE}
        element={
          <React.Suspense fallback={<Loader />}>
            <ComingSoonPage />
          </React.Suspense>
        }
      />
      
      {/* Explicit sign-up route */}
      <Route
        path={REGISTRATION_PAGE}
        element={
          <React.Suspense fallback={<Loader />}>
            <SignUpPage />
          </React.Suspense>
        }
      />
      
      {/* Wildcard route to redirect everything else to Coming Soon */}
      <Route path="*" element={<Navigate to={COMING_SOON_PAGE} replace />}/>
    </Routes>
  );
}