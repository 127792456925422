// General Page Section
export const HOME_PAGE = "/";
export const COMING_SOON_PAGE = "/coming-soon";
export const AGENTS_PAGE = "/agents";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/results";
export const SINGLE_POST_PAGE = "/properties/:slug";


//blog Page
export const BLOGS_PAGE = "/blogs";
export const SINGLE_BLOGS_PAGE = "/blog/:slug";

//Pages
// export const COMPARISON_PAGE = "/property-comparison-tool/:propertyId";
export const ORBIT_PAGE = "/orbit";

//USER PROFILE
export const MY_PROFILE_PAGE = "/my-profile";

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = "/profile";
export const AGENT_PROFILE_FAVORITE = "favorite-post";
export const AGENT_PROFILE_CONTACT = "contact";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_PROFILE_EDIT_PAGE = "edit";
export const AGENT_IMAGE_EDIT_PAGE = "change-image";
export const AGENT_PASSWORD_CHANGE_PAGE = "change-password";
export const AGENT_PROFILE_DELETE = "/delete";

// Other Pages
export const PRICING_PLAN_PAGE = "/pricing-plan";
export const PRIVACY_PAGE = "/privacy";
export const ADD_HOTEL_PAGE = "/add-hotel";
export const LOCATIONS_PAGE = "/locations";

// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";
export const VERIFY_EMAIL_PAGE = "/verify-email";

// Payment/Credits Pages
export const PURCHASE_CREDITS_PAGE = "/purchase-credits";
